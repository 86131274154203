<template lang="pug">
  company-form(v-if="company", v-model="company", :newCompany="false", @submit="updateCompany", @cancel="cancel")
</template>
<script>
import CompanyForm from "@/components/forms/CompanyForm.vue";
import UpdateCompanyMutation from "@/graphql/mutations/companies/UpdateCompanyMutation.gql";
import CompanyQuery from "@/graphql/queries/Company.gql";
export default {
  apollo: {
    company: {
      query: CompanyQuery,
      variables() {
        return { id: this.$route.params.id };
      },
      update({ company }) {
        delete company.workingWeek.__typename;
        return company;
      }
    },
  },
  methods: {
    updateCompany() {
      return this.$apollo
        .mutate({
          mutation: UpdateCompanyMutation,
          variables: {
            input: {
              id: this.company.id,
              name: this.company.name,
              email: this.company.email,
              address1: this.company.address1,
              address2: this.company.address2,
              address3: this.company.address3,
              postcode: this.company.postcode,
              saTimezone: this.company.saTimezone,
              workingWeek: this.company.workingWeek
            },
          },
        })
        .then(() => {
          this.$flash.success("The company was successfully updated");
        });
    },
    cancel() {
      this.$router.push("/companies");
    },
  },
  components: {
    CompanyForm,
  },
};
</script>
